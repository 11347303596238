html,
body {
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    font-family: Montserrat, Tahoma, Arial, sans-serif !important;
    font-size: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
}

mdb-select * {
    font-family: Montserrat, Tahoma, Arial, sans-serif !important;
}

@supports (-webkit-overflow-scrolling: touch) {
    /* CSS specific to iOS devices */
    * {
        cursor: pointer;
    }
}

body {
    padding: 0px;
}

body.staging nav {
    background-color: rgb(118, 194, 0) !important;
}

body.local nav {
    background-color: rgb(255, 200, 75) !important;
}

::-webkit-validation-bubble-message {
    display: none;
}

::-webkit-scrollbar-button {
    display: none;
    height: 13px;
    border-radius: 0px;
    background-color: #AAA;
}

::-webkit-scrollbar-thumb {
    background-color: #727272;
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    background-color: #efefef;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input::-webkit-input-placeholder {
    color: #6c757d !important;
}


/*Switch*/

input[type=checkbox].switch {
    height: 0;
    width: 0;
    display: none;
}

input[type=checkbox].switch+label {
    cursor: pointer;
    text-indent: -9999px;
    width: 50px;
    height: 30px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

input[type=checkbox].switch+label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

input[type=checkbox].switch:checked+label {
    background: #4285f4;
}

input[type=checkbox].switch:checked+label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

input[type=checkbox].switch+label:active:after {
    width: 40px;
}


/*End Switch*/


/*MD Form*/

.md-form input::-webkit-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.md-form input::-moz-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.md-form input:-ms-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.md-form input:focus::-webkit-input-placeholder {
    color: var(--gray);
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.md-form input:focus::-moz-placeholder {
    color: var(--gray);
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.md-form input:focus:-ms-input-placeholder {
    color: var(--gray);
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}


/*End MD Form*/


/*Popover*/

.popover {
    z-index: 4000 !important;
}

.info-popover {
    border-width: 2px !important;
    border-color: var(--primary) !important;
    .popover-header {
        border-bottom: 2px solid var(--primary) !important;
        background: white;
        color: black;
        border-top-left-radius: calc(.3rem - 2px);
        border-top-right-radius: calc(.3rem - 2px);
    }
}

.bs-popover-top.info-popover::before {
    border-top-color: var(--primary) !important;
}

.bs-popover-top.info-popover::after {
    bottom: -8px !important;
}

.bs-popover-bottom.info-popover::before {
    border-bottom-color: var(--primary) !important;
}

.bs-popover-bottom.info-popover::after {
    top: -8px !important;
}

.bs-popover-right.info-popover::before {
    border-right-color: var(--primary) !important;
}

.bs-popover-right.info-popover::after {
    left: -8px !important;
}

.bs-popover-left.info-popover::before {
    border-left-color: var(--primary) !important;
}

.bs-popover-left.info-popover::after {
    right: -8px !important;
}


/*End Popover*/

.fade {
    transition: opacity 0.15s linear;
}

.info-icon {
    margin-right: 2px !important;
    font-size: 18px;
    vertical-align: middle;
    cursor: pointer;
    color: #007bff !important;
}

.select-wrapper .select-dropdown {
    width: auto !important;
    min-width: 100%;
}

.select-wrapper .select-dropdown li {
    white-space: nowrap;
}

.select-wrapper span.caret {
    color: black !important;
    right: 4px !important;
    z-index: 10;
}

.select-wrapper input.select-dropdown {
    margin-bottom: 0 !important;
    padding-right: 20px;
    background-color: white;
    width: 100% !important;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding-left: 5px;
    box-sizing: border-box;
}


/*MDB PRO*/

.testimonial-card .card-up {
    overflow: hidden;
    height: 120px;
    -webkit-border-top-left-radius: .25rem;
    border-top-left-radius: .25rem;
    -webkit-border-top-right-radius: .25rem;
    border-top-right-radius: .25rem;
}

.testimonial-card .card-body {
    text-align: center;
}

.testimonial-card .avatar {
    -webkit-border-radius: 50%;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin-top: -60px;
    overflow: hidden;
    border: 5px solid #fff;
}

.testimonial-card .avatar img {
    width: 100%;
}

.rounded-circle {
    border-radius: 50% !important;
}

.form-check-input[type=checkbox],
.form-check-input[type=radio],
label.btn input[type=checkbox],
label.btn input[type=radio] {
    position: absolute;
    left: -9999px;
    visibility: hidden;
}

.daterangepicker .ranges ul {
    width: auto !important;
}

.css-loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

.css-loader-big {
    border-width: 8px !important;
    width: 80px !important;
    height: 80px !important;
}

.css-loader-small {
    border-width: 2px !important;
    width: 20px !important;
    height: 20px !important;
}

.css-loader-middle {
    border-width: 5px !important;
    width: 30px !important;
    height: 30px !important;
}

.css-loader-white {
    border-left-color: white;
    border-right-color: white;
    border-bottom-color: white;
}


/* Safari */

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.date-range-picker {
    background: #fff;
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    width: fit-content;
    font-size: 12px;
    height: 45px;
    margin: 10px;
    white-space: nowrap;
    display: table;
}

.ranges li {
    height: 45px;
    padding-top: 11px;
    font-size: 16px;
}

.daterangepicker.dropdown-menu {
    width: auto !important;
}

.ranges .range_inputs {
    justify-content: space-around;
    display: flex;
}

.ranges .range_inputs button {
    font-size: 14px;
}

single-employee,
single-product {
    display: contents;
}

.product-list single-product,
.table-list single-table {
    width: 100%;
    max-width: 100%;
    display: block;
    flex: 0 0 100%;
    padding: 0;
}

.full-width {
    width: 100% !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.navbar-full-name {
    float: left;
    margin: 0;
}

.navbar-full-name li a {
    font-size: 22px !important;
}

.full-width-content {
    left: 0 !important;
}

.navbar-nav>li>a {
    font-size: 14px;
    color: white !important;
    text-decoration: none !important;
}

.main-nav {
    &>div {
        height: 100%;
        display: flex;
        &>ul {
            height: 100%;
            display: flex;
            &>li {
                height: 100%;
                display: flex;
                align-items: center;
            }
        }
    }
}

.speech-bubble {
    position: relative;
    background: #ff9800;
    border-radius: .4em;
    width: 98%;
    padding: 10px;
    margin-top: 22px;
    margin-bottom: 20px;
    margin-left: 1%;
    color: white;
    font-size: 17px;
}

.speech-bubble:after {
    content: '';
    position: absolute;
    top: 0;
    right: 10px;
    width: 0;
    height: 0;
    border: 19px solid transparent;
    border-bottom-color: #ffbb33 !important;
    border-top: 0;
    margin-left: -19px;
    margin-top: -19px;
}

.warning-color a {
    color: #0f0686 !important;
}

.card {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
}

.left-container {
    position: absolute;
    left: 0;
    right: 360px;
    top: 0px;
    bottom: 0;
    overflow: auto;
}

.right-container {
    background-color: #f2f2f3;
    position: absolute;
    right: 0;
    top: 0px;
    bottom: 0px;
    width: 360px;
    overflow: auto;
}

.right-container .list-close {
    display: none;
}


/* Wrapping element */


/* Set some basic padding to keep content from hitting the edges */

.body-content {
    height: 100%;
}

.body-content.logged-in {
    padding-top: 0px !important;
}

.row {
    width: 100% !important;
}

.container {
    max-width: 100% !important;
    padding: 0 !important;
}


/*Validation*/

input:required:invalid,
input:focus:invalid {
    background-image: url(../assets/images/asterisk.png) !important;
    background-position: right center !important;
    background-repeat: no-repeat !important;
    -moz-box-shadow: none !important;
}

html[dir=rtl] input:required:invalid,
html[dir=rtl] input:focus:invalid {
    background-position: left center !important;
}


/* Carousel */

.carousel-caption p {
    font-size: 20px;
    line-height: 1.4;
}


/* buttons and links extension to use brackets: [ click me ] */

.btn-bracketed::before {
    display: inline-block;
    content: "[";
    padding-right: 0.5em;
}

.btn-bracketed::after {
    display: inline-block;
    content: "]";
    padding-left: 0.5em;
}


/* Make .svg files in the carousel display properly in older browsers */

.carousel-inner .item img[src$=".svg"] {
    width: 100%;
}

html[dir=rtl] .navbar-right {
    float: left !important;
    margin: 0px !important;
}

.main-nav {
    height: 65px;
    max-height: 65px;
    padding: 0;
    z-index: 2000;
}

.sub-nav {
    max-height: 65px;
    padding: 0 !important;
    z-index: 200000;
    display: none !important;
}

.courosel-container {
    width: 100%;
    position: relative;
}

.main-container {
    height: 500px !important;
    background: #60afff;
}

.container h5 {
    color: white;
    line-height: 1.8;
}


/*Spinner*/

.spinner {
    width: 40px;
    height: 40px;
    position: fixed;
    left: 50%;
    top: 50%;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0.0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}

.multiselect-container>li>a>label>input[type=checkbox] {
    margin-bottom: -3px !important;
    margin-top: 7px !important;
}

.editable-container.popover {
    padding: 10px;
}

.editable-input textarea {
    border: 1px solid #dedede !important;
    padding: 3px !important;
    border-radius: 5px !important;
}

.alert {
    font-size: 15px;
}


/*hide default chat button*/

body.logged-in .zsiq_floatmain {
    display: none !important;
}

.zsiq_floatmain .zsiq_cnt {
    display: none !important;
}

mdb-modal-backdrop:nth-child(2),
mdb-modal-backdrop:nth-child(3),
mdb-modal-backdrop:nth-child(4),
mdb-modal-backdrop:nth-child(5) {
    z-index: 1050;
}

.modal:nth-child(2),
.modal:nth-child(3),
.modal:nth-child(4),
.modal:nth-child(5) {
    z-index: 1060;
}

.modal {
    z-index: 3000;
}

.modal-header {
    height: 65px;
}

.modal-dialog.cascading-modal .modal-header {
    padding: 1.30rem !important;
}

.modal-header h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 35px;
}

.modal-body {
    max-height: calc(100vh - 150px);
    overflow: auto;
}

.modal .modal-dialog {
    margin-top: 0px !important;
    -webkit-will-change: transform;
    -moz-will-change: transform;
    -o-will-change: transform;
    will-change: transform;
}

.modal .modal-dialog.modal-lg {
    width: calc(100% - 40px);
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.modal-header .close {
    font-size: 57px;
    font-weight: 100;
    padding: 0px 16px 0 0;
    margin-top: -14px;
}

b.caret,
.selectbox-caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.bfh-selectbox.open>.bfh-selectbox-options {
    width: 100%;
    margin-bottom: 25px;
}

.bfh-selectbox .bfh-selectbox-options ul {
    max-width: 100%;
}


/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

#gdpr-cookie-message,
#gdpr-cookie-message h4 {
    font-family: inherit !important;
}

#gdpr-cookie-message p:last-child {
    display: flex;
}

#gdpr-cookie-message ul {
    padding-left: 0 !important;
}

#gdpr-cookie-message li {
    width: 50% !important;
    white-space: nowrap;
}

.md-form .prefix~label {
    white-space: nowrap;
}


/*Switch*/

.switch.blue-white-switch label input[type=checkbox]:checked+.lever {
    background-color: #2196f3;
}

.switch.blue-white-switch label input[type=checkbox]:checked+.lever:after {
    background-color: #fff;
}

.switch.blue-white-switch label .lever {
    background-color: #ccc;
    margin: 0;
}

.switch.blue-white-switch label .lever:after {
    background-color: #fff;
}

.switch.round label .lever {
    width: 54px;
    height: 34px;
    border-radius: 10em;
}

.switch.round label .lever:after {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    left: 4px;
    top: 4px;
}

.switch.square label .lever {
    width: 54px;
    height: 34px;
    border-radius: 0px;
}

.switch.square label .lever:after {
    width: 26px;
    height: 26px;
    border-radius: 0px;
    left: 4px;
    top: 4px;
}

.bootbox .modal-footer a,
.bootbox .modal-footer button {
    border-radius: 10em;
}

.danger-text {
    color: #ff3547;
}

.youtube {
    background-color: #000
}

.youtube img {
    background-color: white;
    width: 100%;
    top: -16.82%;
    left: 0;
    opacity: 0.7;
}

.youtube .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}

.youtube .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}

.youtube img,
.youtube .play-button {
    cursor: pointer;
}

.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
    position: absolute;
}

.youtube .play-button,
.youtube .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.youtube iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.introjs-overlay {
    display: none;
}

.introjs-helperLayer {
    background-color: rgba(255, 255, 255, 0.0) !important;
    border: 2px solid var(--primary) !important;
}

.intro-dots {
    padding: 15px 0;
}

.search-form .form-group {
    transition: all 0.35s, border-radius 0s;
    width: 100%;
    height: 36px;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    border-radius: 25px;
    border: 1px solid #ccc;
    position: relative;
}

.search-form .form-group input.form-control {
    padding-left: 30px;
    border: 0 none;
    background: transparent;
    box-shadow: none;
    display: block;
}

.search-form .form-group input.form-control::-webkit-input-placeholder {
    display: none;
}

.search-form .form-group input.form-control:-moz-placeholder {
    /* Firefox 18- */
    display: none;
}

.search-form .form-group input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    display: none;
}

.search-form .form-group input.form-control:-ms-input-placeholder {
    display: none;
}

.search-form .form-group:hover,
.search-form .has-feedback.has-value {
    width: 100%;
    border-radius: 25px;
}

.search-form .form-group span.form-control-feedback {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #3596e0;
    font-size: 14px;
}

.search-form .form-group .pos-search-reset {
    display: none;
}

.search-form .form-group.has-value .pos-search-reset {
    display: block;
    position: absolute;
    right: 10px;
    top: 0px;
    padding: 0;
    margin: 0;
}

.sp-container {
    border: 2px solid var(--primary) !important;
    background: white;
}

#payment-form-container .field {
    position: relative;
    width: 100%;
    height: 65px;
}

#payment-form-container .baseline {
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: #cfd7df;
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#payment-form-container label {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transform-origin: 0 50%;
    cursor: text;
    transition-property: color, transform;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

#payment-form-container .input {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-bottom: 7px;
    color: #32325d;
    background-color: transparent;
}

#payment-form-container .input::-webkit-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#payment-form-container .input::-moz-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#payment-form-container .input:-ms-input-placeholder {
    color: transparent;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

#payment-form-container .input.StripeElement {
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: opacity;
}

#payment-form-container .input.focused,
#payment-form-container .input:not(.empty) {
    opacity: 1;
}

#payment-form-container .input.focused::-webkit-input-placeholder {
    color: var(--gray);
}

#payment-form-container .input.focused::-moz-placeholder,
#payment-form-container .input:not(.empty)::-moz-placeholder {
    color: var(--gray);
}

#payment-form-container .input.focused:-ms-input-placeholder,
#payment-form-container .input:not(.empty):-ms-input-placeholder {
    color: var(--gray);
}

#payment-form-container .input.focused+label,
#payment-form-container .input:not(.empty)+label {
    color: #aab7c4;
    transform: scale(0.85) translateY(-25px);
    cursor: default;
}

#payment-form-container .input.focused+label {
    color: #757575;
}

#payment-form-container .input.invalid+label {
    color: var(--danger);
}

#payment-form-container .input.focused+label+.baseline {
    background-color: var(--primary);
    height: 2px;
}

#payment-form-container .input.focused.invalid+label+.baseline {
    background-color: var(--danger);
}

#payment-form-container input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
}

#payment-form-container input:-webkit-autofill {
    -webkit-text-fill-color: #e39f48;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
}

#payment-form-container .StripeElement--webkit-autofill {
    background: transparent !important;
}

#payment-form-container input,
#payment-form-container button {
    -webkit-animation: 1ms void-animation-out;
}

#payment-form-container .error {
    color: var(--danger);
}

.btn-intro-pervious {
    float: left;
    background: transparent;
    box-shadow: none;
    border: none;
    height: 100%;
    width: calc(50% - 90px);
    text-align: left;
    color: black;
    margin: 0;
    padding-left: 10px;
    padding-right: 0px;
    border-right: 1px solid #EAEAEA;
}

.btn-intro-next,
.btn-intro-finish {
    float: right;
    background: transparent;
    box-shadow: none;
    border: none;
    height: 100%;
    width: calc(50% - 90px);
    text-align: right;
    color: black;
    margin: 0;
    padding-left: 0px;
    padding-right: 10px;
    border-left: 1px solid #EAEAEA;
}

.btn-intro-finish {}

.btn-intro-next:hover,
.btn-intro-pervious:hover,
.btn-intro-next:active,
.btn-intro-pervious:active {
    box-shadow: none !important;
}

.intro-nav {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 0;
    border-top: 1px solid #EAEAEA;
    height: 56px;
    margin-top: 0 !important;
}

.intro-title {
    font-size: 24px;
}

.bfh-selectbox {
    width: 100% !important;
}

.bfh-selectbox-toggle {
    border: 1px solid #ddd !important;
}

div.active-package {
    background-color: #def;
}

div.active-package>div.price-table {
    background-color: white;
}

div.active-package a {
    display: none;
}

.price .price-list .buy-now {
    font-size: 13px !important;
    bottom: -50px !important;
}

.blockUI {
    z-index: 100000 !important;
}

#content {
    width: 100%;
    height: 100vh;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
}

.youtube {
    background-color: #000;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.youtube img {
    width: 100%;
    top: -16.84%;
    left: 0;
    opacity: 0.7;
}

.youtube .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
}

.youtube .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
}

.youtube img,
.youtube .play-button {
    cursor: pointer;
}

.youtube img,
.youtube iframe,
.youtube .play-button,
.youtube .play-button:before {
    position: absolute;
}

.youtube .play-button,
.youtube .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.youtube iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}

.carousel .fa-angle-left,
.carousel .fa-angle-right {
    font-size: 30px;
    color: black;
}

#toast-container>mdb-toast-component {
    width: 400px !important;
    max-width: 100% !important;
}

.overlay-container {
    display: flex;
    justify-content: center;
}

.md-toast-top-center {
    right: auto !important;
    width: auto !important;
}

.toast-bottom-center {
    width: auto !important;
    right: 50% !important;
    margin-right: -150px;
}

.loading-toast.md-toast-info {
    background-image: none !important;
    display: flex !important;
    padding-left: 20px !important;
}

.loading-toast.md-toast-info::before {
    content: " ";
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
    border: 2px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.animated.faster {
    -webkit-animation-duration: .2s;
    animation-duration: .2s
}

.dz-preview.dz-processing.dz-image-preview.dz-error {
    border: 1px solid #ffb9b9;
    background: #ffcccc;
    border-radius: 8px;
}

.pos-product-list .out-of-stock {
    top: 26px;
    left: -150px;
    -webkit-transform: rotate(-31deg) scale(0.95, 1);
}

.out-of-stock {
    width: 250px;
    top: 37px;
    left: -74px;
    color: #fff;
    display: block;
    position: absolute;
    text-align: center;
    text-decoration: none;
    letter-spacing: .06em;
    background-color: #A00;
    padding: 0.5em 4em 0.4em 5em;
    text-shadow: 0 0 0.75em #444;
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    font: bold 16px/1.2em Arial, Sans-Serif;
    -webkit-text-shadow: 0 0 0.75em #444;
    -webkit-box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
    -webkit-transform: rotate(-45deg) scale(0.75, 1);
    z-index: 10;
}

.out-of-stock::before {
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    margin: -0.3em -5em;
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    border: 2px rgba(255, 255, 255, 0.7) dashed;
}

.picker__box .picker__header .picker__date-display {
    display: flex;
    justify-content: center;
    background-color: #4285f4;
    font-weight: 400;
    padding-bottom: .3125rem;
}

.picker__box .picker__header .picker__date-display .picker__year-display {
    font-size: 1.1rem;
    color: rgba(255, 255, 255, .4);
    position: absolute;
    top: .625rem;
    left: 45%;
}

.picker__box .picker__header .picker__date-display .picker__weekday-display {
    padding: .875rem .4375rem .3125rem .5rem;
    letter-spacing: .5;
    font-size: 2.1rem;
    margin-top: 1.25rem;
    background: inherit;
}

.picker__box .picker__header .picker__date-display .picker__month-display {
    font-size: 2.1rem;
    padding: .875rem .3125rem .25rem;
    margin-top: 1.25rem;
}

.picker__box .picker__header .picker__date-display .picker__day-display {
    font-size: 2.1rem;
    padding: .875rem .3125rem .25rem;
    margin-top: 1.25rem;
}

.picker__box .picker__footer .picker__button--today,
.picker__box .picker__footer .picker__button--clear,
.picker__box .picker__footer .picker__button--close {
    display: flex !important;
}

.picker__box .picker__footer .picker__button--today:before,
.picker__box .picker__footer .picker__button--clear:before {
    top: 0.5em;
}

.table-responsive>.table-bordered {
    border: 1px solid #dee2e6;
}

.informative-text {
    font-size: 18px;
}

.zsiq_theme1 div.zsiq_cnt {
    display: none !important;
}

.md-form label {
    font-size: 13px !important;
}

.md-form>input[type]:-webkit-autofill:not(.browser-default):not([type='search'])+label {
    width: auto !important;
}

.sl-content-wrapper {
    height: 100% !important;
    width: 100% !important;
}

.sl-slider {
    height: 100% !important;
    width: 100% !important;
}

.sl-slider h2 {
    font-size: 50px;
    line-height: 1;
}

#toast-container.md-toast-bottom-center>mdb-toast-component {
    width: 500px !important;
    max-width: 80% !important;
}


/* Cookie Dialog */

#gdpr-cookie-message {
    position: fixed;
    right: 30px;
    bottom: 100px;
    max-width: 375px;
    background-color: #3B3646;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.25);
    margin-left: 30px;
    font-family: system-ui;
    z-index: 1500
}

#gdpr-cookie-message h4 {
    color: #EE4B5A;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
}

#gdpr-cookie-message h5 {
    color: #EE4B5A;
    font-family: 'Quicksand', sans-serif;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
}

#gdpr-cookie-message p,
#gdpr-cookie-message ul {
    color: white;
    font-size: 15px;
    line-height: 1.5em;
}

#gdpr-cookie-message p:last-child {
    margin-bottom: 0;
    text-align: right;
}

#gdpr-cookie-message li {
    width: 49%;
    display: inline-block;
}

#gdpr-cookie-message a {
    color: #EE4B5A;
    text-decoration: none;
    font-size: 15px;
    padding-bottom: 2px;
    border-bottom: 1px dotted rgba(255, 255, 255, 0.75);
    transition: all 0.3s ease-in;
}

#gdpr-cookie-message a:hover {
    color: white;
    border-bottom-color: #EE4B5A;
    transition: all 0.3s ease-in;
}

#gdpr-cookie-message button {
    border: none;
    background: #EE4B5A;
    color: white;
    font-family: 'Quicksand', sans-serif;
    font-size: 15px;
    padding: 7px;
    border-radius: 3px;
    margin-left: 10px;
    cursor: pointer;
    transition: all 0.3s ease-in;
}

#gdpr-cookie-message button:hover {
    background: white;
    color: #EE4B5A;
    transition: all 0.3s ease-in;
}

#gdpr-cookie-accept {
    margin-left: 0px !important;
}

button#gdpr-cookie-advanced {
    background: white;
    color: #EE4B5A;
}

#gdpr-cookie-message button:disabled {
    opacity: 0.3;
}

#gdpr-cookie-message input[type="checkbox"] {
    float: none;
    margin-top: 0;
    margin-right: 5px;
}

.block-ui-wrapper {
    background: rgba(0, 0, 0, 0.5) !important;
}

#ordersList .accordion .card .card-header {
    padding: 0 !important;
    background: #34B5E5;
}

#ordersList .accordion .card .card-body {
    padding: 0 !important;
}

.modal-dialog.modal-info .modal-header {
    background-color: #33b5e5 !important;
    .heading,
    .close {
        color: white;
    }
}

@keyframes blink {
    0% {
        border: 4px solid transparent;
    }
    50% {
        border-color: #f37736;
    }
    100% {
        border: initial;
    }
}

.highlight {
    animation: blink .5s step-end 16 alternate;
}

.btn-floating {
    position: relative !important;
}

dropzone>.dropzone.dz-wrapper {
    border: 2px dashed #aaa !important;
    border-radius: 7px;
}

.dropzone .dz-preview .dz-image {
    height: 120px !important;
}

.dropzone .dz-preview .dz-image img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    object-fit: contain;
}

dropzone>.dropzone.dz-wrapper .dz-message {
    border: none !important;
    background: none !important;
    margin: 0 !important;
}

dropzone>.dropzone.dz-wrapper .dz-preview {
    margin-top: 0 !important;
}

dropzone>.dropzone.dz-wrapper.dz-single .dz-preview {
    margin-top: 8px !important;
}

.dropdown-menu-right {
    left: auto !important;
}

.date-range-toggle:before {
    content: "▼";
}

.pagination .page-item .page-link {
    background-color: #4285f4 !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    color: white;
    border-radius: 4px !important;
    font-size: 25px;
    padding: 8px 15px 12px 15px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination .page-item.disabled .page-link {
    background-color: transparent !important;
    box-shadow: none !important;
    color: lightgray !important;
}

.sticky-top {
    z-index: 1000 !important;
}

.cdk-overlay-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5000;
    .cdk-overlay-backdrop {
        background: #00000052 !important;
        width: 100%;
        height: 100%;
        opacity: 1;
    }
}

mdb-date-picker .md-form {
    margin: 0;
}

div.zsiq_floatmain {
    z-index: 1000 !important;
}

.no-radius {
    border-radius: 0px;
}

.action-toolbar {
    display: flex;
    margin: 0px 0px 20px;
    padding: 10px;
    background-color: #eceff1 !important;
}

.toolbar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    i {
        font-size: 20px !important;
        margin-right: .5rem;
    }
}

button.background-white,
a.background-white {
    background-color: white !important;
}

button.background-white:hover,
a.background-white:hover {
    background-color: white !important;
}

button.background-white:active,
a.background-white:active {
    background-color: white !important;
}

div.mdb-select-wrapper {
    overflow: hidden;
    height: 41px;
}

.mdb-select-value {
    width: 100% !important;
}

span.mdb-select-arrow {
    right: 0;
    top: 0;
    bottom: 1px;
    padding-top: 12px;
    background: white;
    width: 34px;
}

.cdk-overlay-container {
    z-index: 10510 !important;
}