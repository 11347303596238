@keyframes heart-beat-delay {
  0% {
    transform: scale(1);
  }

  3% {
    transform: scale(1.3);
  }

  6% {
    transform: scale(1);
  }

  9% {
    transform: scale(1.3);
  }

  15% {
    transform: scale(1);
  }
}
