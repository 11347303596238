@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  html[dir=ltr] .product-toolbar-right {
    text-align: right !important;
  }

  html[dir=rtl] .product-toolbar-right {
    text-align: left !important;
  }

  .pos-category-container {
    animation-name: nonexisting;
  }

  .category-opener {
    display: none;
  }
}

@media (min-width: 576px) {
  .datev-export-header label {
    padding-top: 15px;
  }
}

@media (min-width: 667px) {
  .pos-container .product-view {
    width: 33.3333%;
  }
}

@media screen and (min-width: 769px) {
  .table-name-cont {
    display: block !important;
  }

  .pos-course-list {
    top: 93px !important;
  }

  html[dir=ltr] .receipt-single-item.activereceipt::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 20px 25px;
    border-color: transparent transparent transparent #0c84e4;
    position: absolute;
    right: -24px;
    top: 25%;
  }

  .receipts-list, .zreport-list {
    padding-right: 25px;
  }
}

@media (min-width: 1024px) {
  .pos-container .product-view {
    width: 25%;
  }
}


@media (max-width: 1024px) {
  /*IPAD*/
  .pos-table-cont {
    width: 108px !important;
    height: 108px !important;
    margin: 3px !important;
    border-radius: 0 !important;
    border-width: 2px !important;
  }

  .pos-table-cont .pos-table-name {
    height: 50px !important;
    line-height: 50px !important;
    font-size: 17px;
  }

  .pos-table-cont .order-date-time, .pos-table-cont .order-info {
    height: 30px !important;
    padding-top: 0px !important;
    line-height: 30px !important;
    vertical-align: middle !important;
    font-size: 12px;
  }

  .pos-table-cont .order-info {
    text-align: right;
    padding-right: 2px;
    font-size: 11px !important;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
}

@media (max-width: 768px) {
  /*IPAD*/
  .receipts-list.hidden-list, .zreport-list.hidden-list {
    display: none;
  }

  .receipt-overview-fullwidth {
    position: fixed !important;
    top: 0px !important;
    left: 0px;
    right: 0;
    z-index: 10000 !important;
    background-color: #9a9a9a;
    padding: 0 !important;
  }

  .receipt-overview-fullwidth .close-receipt-overview {
    display: block !important;
    position: absolute;
    right: 20px;
    top: 5px;
    cursor: pointer;
  }


  .receipt-overview-fullwidth .receipt-overview {
    width: 100%;
    margin: 0;
    height: auto !important;
    overflow: auto;
    margin-top: 0px !important;
    position: absolute !important;
    top: 65px;
    bottom: 0px;
  }

  .receipt-overview-fullwidth .receipt-overview .receipt-container {
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    max-height: calc(100% - 10px) !important;
    position: absolute !important;
    bottom: 10px;
    top: 10px;
    overflow: auto;
    left: 10px;
    right: 10px;
    width: auto !important;
    margin-bottom: 0px;
  }

  .receipt-overview-fullwidth .zreport-header {
    margin-top: 20px !important;
    display: inline-table;
  }

  .zreport-overview, .receipt-overview {
    position: absolute !important;
    top: 66px;
    width: 100%;
    bottom: 0px;
    padding: 0 !important;

  }

  .receipt-container, #zreport-container {
    height: 100% !important;
    margin: 0 !important;
    padding-top: 55px;
    width: 100% !important;
    border-radius: 0;
    box-shadow: none;
  }

  .printer-setting-field, .printer-category-container .select-wrapper, .printer-settings-container .select-wrapper {
    width: 100% !important;
  }

  .printer-settings-label {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .category-list li.active:after {
    display: none;
  }
}

@media (max-width: 768px) {
  .right-container {
    position: fixed !important;
    left: 100% !important;
    right: 200% !important;
    top: 0px !important;
    width: 100% !important;
    z-index: 2000 !important;
  }

  .right-container-slide-left {
    -ms-transition: all .3s;
    -o-transition: all .3s;
    -webkit-transition: all .3s;
    transition: all .3s;
    -ms-transform: translate3d(-100%, 0, 0) !important;
    -webkit-transform: translate3d(-100%, 0, 0) !important;
    transform: translate3d(-100%, 0, 0) !important;
    -webkit-will-change: transform;
    -moz-will-change: transform;
    -o-will-change: transform;
    will-change: transform;
  }

  .right-container-slide-right {
    -ms-transition: all .3s;
    -o-transition: all .3s;
    -webkit-transition: all .3s;
    transition: all .3s;
    -ms-transform: translate3d(100%, 0, 0) !important;
    -webkit-transform: translate3d(100%, 0, 0) !important;
    transform: translate3d(100%, 0, 0) !important;
    -webkit-will-change: transform;
    -moz-will-change: transform;
    -o-will-change: transform;
    will-change: transform;
  }

  .navbar-pos-order-list {
    display: block !important;
  }

  .left-container {
    right: 0px !important;
  }

  #menuModal .modal-dialog {
    margin-left: 0px;
  }

  #menuModal, .modal-backdrop {
    right: 0px !important;
  }

  .owl-carousel-wrap {
    margin: 0 15px !important;
  }

  .pos-course-list {
    top: 118px;
  }

  .retail-payment-container {
    top: 65px !important;
  }

  .btn-show-retail-payment {
    display: block !important;
  }

  .pos-product-list .panel-footer a.btn {
    width: 50px;
    height: 50px;
    padding: 15px;
  }

  .pos-product-list .panel-footer .btn span {
    display: none;
  }

}

@media screen and (max-width: 767px) {
  html[dir=ltr] .product-toolbar-right {
    text-align: left !important;
  }

  html[dir=rtl] .product-toolbar-right {
    text-align: right !important;
  }

  .col-seperator {
    border-right: none !important;
  }

  h1, h2 {
    font-size: 34px !important;
  }

  /* Hide captions */
  .carousel-caption {
    display: none
  }

  .navbar-brand {
    display: none;
  }

  .table-action-button {
    width: 100%;
    margin: 4px 0px;
  }

  .btn-add-product, .btn-import-product {
    width: calc(100% - 20px);
    margin: 4px 10px;
  }

  .modal .modal-dialog {
    margin-left: auto !important;
    margin-right: auto !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
  }

  .settings-receipt-container {
    max-width: 100%;
  }

  .pos-category-container {
    position: absolute;
    z-index: 100;
  }

  .receipt-tab-container {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media screen and (max-width: 667px) {
  .section {
    font-size: 12px !important;
    width: auto !important;
    margin: 0 !important;
  }

  .section-header {
    font-size: 18px !important;
  }

  .pos-container .product-view {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .hide-small {
    display: none !important;
  }
}

@media (max-width: 414px) {
  /*Iphone 6/7/8 plus*/
  .table-toolbar .table-action {
    width: 100%;
  }

  .action-toolbar {
    flex-direction: column !important;
    width: 100%;
  }

  .action-toolbar .toolbar-item {
    width: calc(100% - 20px) !important;
    margin: 10px;
  }

  .date-range-picker {
    width: calc(100% - 20px);
  }

  .receipt-type-container {
    text-align: center !important;
  }

  .total-sum-container {
    text-align: left !important;
  }

  .select-wrapper .select-dropdown {
    left: 0 !important;
  }

  .right-container-slide-left {
    animation-duration: 0.4s;
  }

  .right-container-slide-right {
    animation-duration: 0.4s;
  }

}

@media screen and (max-width: 375px) {
  /*Iphone  6/7/8 */
  .intro-video {
    width: 340px !important;
    height: 191px !important;
  }
}

@media screen and (max-width: 320px) {
  /*Iphone  5*/
  .owl-carousel .item {
    height: 390px !important;
  }
}
